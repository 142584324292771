import axios, { AxiosError } from 'axios';
import api from '@/api/api';
import { snackbarBuilder } from '@/services/snackbars';
import store from '@/store';
import { Apartment, TicketFields } from '@/types/types';
import { CookieUtils } from '@/services/cookies';
import { requestPermissionAndSendNotificationToken } from '@/services/handleNotifs';

export const updateMatches = async () => {
  try {
    const newAudit = await Profile.updateAudit();
    await Profile.requestNewMatch();
    await Apartments.reload();
  } catch (error) {
    throw error;
  }
}

export interface DecodedGoogleCallback {
  aud:string
  azp:string
  email:string
  email_verified:boolean
  exp:number
  family_name:string
  given_name:string
  iat:number
  iss:string
  jti:string
  name:string
  nbf:number
  picture:string
  sub:number
}

interface Login {
  email?:string
  password?:string
}

export namespace Register {
  export const postFcmToken = async (token:string) => {
    try {
      const response = await api.post('register', {registerToken:token});
      console.log('Token sent to server');
    } catch (error){
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }
}

export namespace Session {
  export const firstLogin = async (login:Login) => {
    try {
      const response = await api.post('auth/student-login', login);
      const { authData, detailsData } = response.data.data;
      CookieUtils.saveToken(authData);
      store.dispatch('logIn', authData);
      store.dispatch('setDetails', detailsData);
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "login");
      return false;
    }
  }

  const canSkipIntro = (preferences: any) => {
    return (
      preferences.cities 
      && preferences.cities.length > 0 
      && preferences.maxRent != null 
      && preferences.maxRent != "" 
      && preferences.minSurface != null 
      && preferences.minSurface != "" 
      && preferences.minRooms != null 
      && preferences.minRooms != "" 
      && preferences.aptType 
      && preferences.aptType.length > 0 
      && preferences.shouldBeFurnished !== "?" 
      && preferences.shouldBeFurnished != null
      && preferences.shouldBeFurnished !== ""
      && preferences.leaseLen != ""
      && preferences.leaseLen != null
    );
  }

  export const googleLoginRequest = async (credential: string) => {
    try {
      const response = await api.post('auth/google', { credential });
      
      const { authData, detailsData } = response.data.data;
      
      CookieUtils.saveToken(authData);
      store.dispatch('logIn', authData);
      store.dispatch('setDetails', detailsData);
      
      if (!canSkipIntro(detailsData.preferences)) {
        return { canLogIn: true, pushToIntro: true };
      }

      const {
        appliances, 
        audit, 
        compatibleApartments,
        files, 
        likedApartments
      } = response.data.data;

      store.dispatch('setLikedApartments', likedApartments);
      store.dispatch('setAppliances', appliances);
      store.dispatch('setAudit', audit);
      store.dispatch('setFiles', files);
      store.dispatch('setAllCompatibleApartments', detailsData.compatibleApts);
      store.dispatch('setCompatibleFullApartments', compatibleApartments)
      
      requestPermissionAndSendNotificationToken();
      return { canLogIn: true, pushToIntro: false };
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "login");
      store.dispatch('logOut');
      return { canLogIn: false, pushToIntro: false };
    }
  };

  export const loginRequest = async (login:Login={}) => {
    try {
      const token = CookieUtils.getToken();
      if (!token && !login) return {canLogIn:false, pushToIntro:false};
      const endpoint = login.email ? 'auth/student-login' : 'auth/cookies-login';
      const data = login.email ? { email:login.email, password:login.password } : {};
      const response = await api.post(endpoint, data);

      const {authData,detailsData} = response.data.data; 

      CookieUtils.saveToken(authData);
      store.dispatch('logIn', authData);
      store.dispatch('setDetails', detailsData);
      
      if (!canSkipIntro(detailsData.preferences)) {
        return { canLogIn:true,  pushToIntro:true};
      }
      
      const {
        appliances, 
        audit, 
        compatibleApartments,
        files, 
        likedApartments
      } = response.data.data;

      store.dispatch('setLikedApartments', likedApartments);
      store.dispatch('setAppliances', appliances);
      store.dispatch('setAudit', audit);
      store.dispatch('setFiles', files);
      store.dispatch('setAllCompatibleApartments', detailsData.compatibleApts);
      store.dispatch('setCompatibleFullApartments', compatibleApartments)
      
      requestPermissionAndSendNotificationToken();
      return {canLogIn:true, pushToIntro:false};
    } catch (error){
      store.dispatch('logOut');
      if (login.email){
        const code = defineError(error);
        snackbarBuilder(code, true, "login");
      }
      return {canLogIn:false, pushToIntro:false};
    }
  }
  
  export const logoutRequest = async () => {
    try {
      store.dispatch('logOut');
      const response = await api.post('auth/logout');
      return true;
    } catch (error){
      const code = defineError(error);
      // snackbarBuilder(code, true, "Impossible de se déconnecter");
      return false;
    }
  }

  export const postUser = async (signupForm:Record<string,string>) => {
    try {
      const response = await api.post('users/signup', signupForm);
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "signup");
      return false;
    }
  }
}

export namespace Tickets {
  export const postRequest = async (ticket:TicketFields) => {
    try {
      const response = await api.post('/tickets', ticket);
      snackbarBuilder(response.status, false, "tickets");
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }
}

export namespace Appliances {
  export const postRequest = async (appliance:any) => {
    try {
      const response = await api.post('/appliances', appliance);
      const data = response.data.data;
      store.dispatch('postAppliance', data);
      snackbarBuilder(response.status, false, "appliances");
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "appliances");
      return false;
    }
  }
}

export namespace Apartments {
  export const rewindRequest = async (apartment:Apartment, lastAction:string, skipRewind=false) => {
    try {
      const data = { lastApt: { id: apartment._id, score: apartment.score, faved: apartment.isFaved } }
      const params = { lastAction:lastAction, skipRewind:skipRewind }
      const response = await api.put(`/students/${store.getters.getUserId}/rewind`, data, {params});
      if (lastAction !== 'declined') store.dispatch('removeApartmentFromLikes', apartment._id);
      store.dispatch('updateAfterRewind', apartment);
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }

  export const swipeRequest = async (apartment:Apartment, action:string, faved=false) => {
    try {
      const data = { lastApt: { id:apartment._id, score:apartment.score, faved }};
      const params = { seenAptId: apartment._id, action }
      const response = await api.put(`/students/${store.getters.getUserId}/swipe`, data, {params});
      store.dispatch('updateAfterSwipe', {apartment:{...apartment, isFaved:faved}, action:action})
      const lastAction = faved ? 'faved' : action;
      store.dispatch('pushLastAction', lastAction);
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }

  export const reload = async () => {
    try {
      const response = await api.get(`/students/${store.getters.getUserId}/reload`);
      console.log('===>', response)
      store.dispatch('setCompatibleFullApartments', response.data.data.apartments)
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }
}

export namespace Profile {
  export const simpleUpdate = async (updatedProfile:any, storeAction:string, hasPayload=true) => {
    try {
      const data = {...updatedProfile, role:'STUDENT'};
      const response = await api.put(`/users/${store.getters.getUserId}`, data);
      hasPayload ? store.dispatch(storeAction, response.data.data) : store.dispatch(storeAction);
      console.log('resp from update profile:', response)
      return true
    } catch (error){
      return false 
    }
  }

  export const putRequest = async (updatedProfile:any, storeAction:string, hasPayload=true) => {
    try {
      const data = {...updatedProfile, role:'STUDENT'};
      const response = await api.put(`/users/${store.getters.getUserId}`, data);
      hasPayload ? store.dispatch(storeAction, response.data.data) : store.dispatch(storeAction);
      snackbarBuilder(response.status, false, "modification");
      return true;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }

  export const getS3SignedUrlDownloadPdf = async (fileKey:string) => {
    try {
      const response = await api.get('files/ressources', {params:{fileKey:fileKey}});
      return response.data.data;
    } catch (error) {
      return false;
    }
  }

  export const getS3SignedUrl = async (fileKey:string) => {
    try {
      const response = await api.get('files/ressources', {params:{fileKey:fileKey}});
      store.dispatch('setUserAvatarUrl', response.data.data);
      snackbarBuilder(response.status, true, "");
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  export const updateAudit = async () => {
    try {
      const response = await api.get(`/students/${store.getters.getUserId}/audit`);
      store.dispatch('setAudit', response.data.data);
      return response.data.data;
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }

  export const requestNewMatch = async () => {
    try {
      const response = await api.get(`/students/${store.getters.getUserId}/search`);
      store.dispatch('setAllCompatibleApartments', response.data.data.compatibleApts);
    } catch (error) {
      const code = defineError(error);
      snackbarBuilder(code, true, "");
      return false;
    }
  }
}

const defineError = (error:AxiosError|any) => {
  return axios.isAxiosError(error) && error.response ? error.response.status : 500;
}